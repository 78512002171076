import { registerApplication, start } from 'single-spa'
import * as envVars from 'env-vars'
import * as pinia from 'pinia'
import FileSaver from 'file-saver'
import maze from './maze'
import '@kpmg/styleguide/dist/style'

// This instructions permits uses of Vite external's
// plugin in order to limit bundle sizes and permit ommon reactivities
window.Pinia = pinia
window.EnvVars = envVars
window.FileSaver = FileSaver

const styleguideVersion = require('../package.json')
window.styleguideVersion = styleguideVersion.dependencies['@kpmg/styleguide']

function registerMfe(mfeName) {
  registerApplication({
    name: `@kpmg/${mfeName}`,
    app: () =>
      import(
        /* webpackIgnore: true */
        `@kpmg/${mfeName}`
      ),
    activeWhen: ['/'],
  })
}

await Promise.all([
  import('@kpmg/common'),
  import('@kpmg/styleguide'),
])
  .then(([
    common,
    Styleguide,
  ]) => {
    window.Common = common
    window.Styleguide = Styleguide
    if (window.isProd) {
      // MAZE Script
      if (envVars.VITE_MAZE_KEY) {
        maze(window, document, '/libs/maze-universal-loader.js', envVars.VITE_MAZE_KEY)
      }
      // Script OneTrust
      if (envVars.VITE_ONE_TRUST_KEY) {
        const script = document.createElement('script')
        script.src = `https://cdn.cookielaw.org/consent/${envVars.VITE_ONE_TRUST_KEY}/otSDKStub.js`
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('charset', 'UTF-8')
        script.setAttribute('data-domain-script', envVars.VITE_ONE_TRUST_KEY)
        document.body.appendChild(script)
      }
    }
    const mfes = [
      'entities',
      'ged',
      'mandate',
      'login',
      // 'schedule',
      'launcher',
    ]
    mfes.forEach((mfe) => {
      registerMfe(mfe)
    })

    start({
      urlRerouteOnly: true,
    })
  })

window.addEventListener('single-spa:first-mount', () => {
  registerMfe('router')
})
